<template>
  <v-btn :loading="loading" color="green" icon @click="download">
    <v-icon>mdi-cloud-download</v-icon>
  </v-btn>
</template>
<script>
import axios from "axios";

export default {
  name: 'ExportRanking',
  props: ['exam_id'],
  data() {
    return {
      dialog: false,
      file: null,
      loading: false,
    }
  },
  computed: {
    form() {
      let formData = new FormData()
      formData.append('exam_id', this.exam_id)
      return formData
    }
  },
  methods: {
    download() {
      const url = 'exam-reports?action=export&filter=web&id=' + this.exam_id
      axios.get(url, {
        headers:
            {
              'Content-Disposition': "attachment; filename=template.xlsx",
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
        responseType: 'arraybuffer',
      }).then((response) => {
        console.log(response)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', Math.floor((Math.random() * 999999999) + 1) + '.xlsx');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        console.log(error)
      })
    },
  },
}
</script>