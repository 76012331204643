import { render, staticRenderFns } from "./WebExamReport.vue?vue&type=template&id=61c6539d&scoped=true"
import script from "./WebExamReport.vue?vue&type=script&lang=js"
export * from "./WebExamReport.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c6539d",
  null
  
)

export default component.exports