<template>
  <v-card>
    <v-card-title>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          hide-details
      ></v-text-field>
      <export-ranking v-if="isAdmin" :exam_id="$route.params.examId"></export-ranking>
    </v-card-title>
    <v-data-table
        :headers="isAdmin ? adminHeaders : headers"
        :items="reportsWithPosition"
        :search="search"
        :hide-default-footer="true"
        disable-pagination
        :sort-by="['obtained_marks']"
        :sort-desc="[true]"
        :mobile-breakpoint="0"
    >
      <template v-slot:item.duration="{ item }">
        {{ item.duration | duration }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.updated_at | standardDate }}
      </template>
    </v-data-table>
    <v-card-actions class="justify-center">
      <v-btn
          outlined
          rounded
          color="primary"
          large
          @click="$router.go(-1)"
      >
        Back
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import axios from "axios";
import * as moment from "moment";
import {mapGetters} from "vuex";
import ExportRanking from "../../admin/course/ExportRanking";

export default {
  name: "WebExamReport",
  components: {ExportRanking},
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Position',
          value: 'position'
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Obtained Marks',
          value: 'obtained_marks'
        },

      ],
      adminHeaders: [
        {
          text: 'Position',
          value: 'position'
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Phone',
          value: 'phone',
        },
        {
          text: 'Obtained Marks',
          value: 'obtained_marks'
        },
        {
          text: 'Submission Time',
          value: 'updated_at'
        }
      ],
      reports: []
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/admin'
    }),
    reportsWithPosition() {
      return this.reports.map((r, index) => ({...r, position: index + 1}))
    }
  },
  methods: {
    initialize() {
      const url = 'exam-reports?filter=web&id=' + this.$route.params.examId
      axios.get(url).then((response) => {
        this.reports = response.data
      })
    }
  },
  mounted() {
    this.initialize()
  },
  filters: {
    duration(time) {
      let hour = 0;
      let minute = 0;
      let second = time;
      hour = Math.floor(second / 3600)
      second = second % 3600
      minute = Math.floor(second / 60)
      second = second % 60
      return hour + ':' + minute + ':' + second
    },
    standardDate(value) {
      return moment(value).format('D-M-Y hh:mm a')
    }
  }
}
</script>

<style scoped>

</style>